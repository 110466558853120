/* inter-regular - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/inter-v11-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("./assets/fonts/inter-v11-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/inter-v11-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/inter-v11-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/inter-v11-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/inter-v11-latin-regular.svg#Inter") format("svg"); /* Legacy iOS */
}
/* inter-600 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/inter-v11-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("./assets/fonts/inter-v11-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/inter-v11-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/inter-v11-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/inter-v11-latin-600.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/inter-v11-latin-600.svg#Inter") format("svg"); /* Legacy iOS */
}
